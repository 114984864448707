<template>
  <div class="terrain-types">
    <div class="terrain-types__title">Тип местности</div>
    <div class="terrain-types__switcher">
      <terrain-type-item
        v-for="type in types"
        :key="type.name"
        :type="type"
        :current-type="currentType"
        @updateType="updateType"
      />
    </div>
  </div>
</template>

<script>
import TerrainTypeItem from '@/components/dump/Location/TerrainTypeItem'
const types = [
  {
    name: 'A',
    src: require('@/assets/img/A.jpg'),
    text:
      'открытые побережья морей, озер и водохранилищ, сельские местности, в том числе с постройками высотой менее 10 м, пустыни, степи, лесостепи, тундра'
  },
  {
    name: 'B',
    src: require('@/assets/img/B.jpg'),
    text:
      'городские территории, лесные массивы и другие местности, равномерно покрытые препятствиями высотой более 10м;'
  },
  {
    name: 'C',
    src: require('@/assets/img/C.jpg'),
    text: 'городские районы с плотной застройкой зданиями высотой более 25 м.'
  }
]
export default {
  props: {
    currentType: {
      type: String,
      default: null
    }
  },
  name: 'TerrainTypes',
  methods: {
    updateType(type) {
      this.$emit('updateType', type)
    }
  },
  components: { TerrainTypeItem },
  computed: {
    types: () => types
  }
}
</script>

<style scoped lang="sass">
.terrain-types
  +media((padding: (0: 0 rem(24), 992: 0)))
  &__title
    font-style: normal
    font-weight: 500
    color: $black
    text-align: left
    +media((margin: (320: rem(40) 0 rem(16), 992: rem(51) 0)))
    +media((font-size: (320: rem(22), 992: rem(28))))
    +media((line-height: (320: rem(33), 992: rem(39))))
  &__switcher
    display: grid
    +media((grid-template-columns: (0: 1fr, 768: repeat(3, 1fr))))
    grid-column-gap: rem(5)
    grid-row-gap: rem(1)
</style>
