<template>
  <section class="location">
    <Nav :isInLocation="true" @goToLocation="goToLocation" @goToBase="goToBase" />
    <div class="container">
      <div class="row">
        <div class="location__title-wrapper">
          <div class="location__title minor__title">Ветровой район</div>
        </div>
        <div class="location__pane common__pane">
          <div class="location__pane-title common__title">
            Карта районирования территории РФ по давлению ветра
          </div>
          <div class="location__pane-map">
            <Yamap @setZoneParams="setZoneParams" @setAddressFromSearchBox="updateUserAddress" />
          </div>
          <div class="location__pane-inputs">
            <div class="location__pane-inputs--zone">
              <div class="title">Ветровой район</div>
              <CustomSelect
                :options="filteredDistricts"
                :optionsModel="location.zone"
                :label="zoneLabel"
                @update="updateZone($event)"
              />
              <span class="tooltip__btn">
                <tippy :max-width="240">
                  <template #trigger>
                    <img src="@/assets/img/question.svg" alt="?" />
                  </template>
                  {{ zone }}
                </tippy>
              </span>
            </div>
            <div class="location__pane-inputs--pressure">
              <div class="title">Расчетное ветровое давление</div>
              <div
                class="input input--disabled"
                content="Чтобы активировать выберите <br>вариант “свой” в выборе зоны"
                v-if="!isCustomZone"
                v-tippy
              >
                {{ location.pressure }}
              </div>
              <input type="number" class="input" v-else v-model.number="computedPressure" />
              <div v-if="!isCustomZone" class="title">кПа</div>
              <CustomSelect
                v-if="isCustomZone"
                :options="units"
                :optionsModel="currentUnit"
                :label="unitLabel"
                @update="setCurrentUnit($event)"
              />
              <span class="tooltip__btn">
                <tippy :max-width="240">
                  <template #trigger>
                    <img src="@/assets/img/question.svg" alt="?" />
                  </template>
                  {{ pressure }}
                </tippy>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <terrain-types :current-type="location.type" @updateType="updateType" />
        <div class="buttons__nav location__buttons-nav">
          <button @click="goToWizard" class="common__button-next" :disabled="isNextStepBtnDisabled">
            Основание кровли
            <img src="@/assets/img/common-arrow-next.svg" alt="→" />
          </button>
        </div>
      </div>
    </div>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="1"
      backgroundColor="#eeeeee"
      :z-index="4"
      is-full-page
    ></loading>
  </section>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { clamp, isSomeSectorMinorResultZoneUnsetted } from '@/utils'
import Nav from '@/components/smart/Nav.vue'
import Yamap from '@/components/smart/Yamap.vue'
import TerrainTypes from '@/components/dump/Location/TerrainTypes'
import { stepNumbers } from '@/utils/stepNumbers'
import Loading from 'vue-loading-overlay'
import CustomSelect from '@/components/dump/CustomSelect.vue'
import { uuid } from 'vue-uuid'
import { PRESSURE_UNITS } from '@/components/helper'

const districts = [
  {
    id: 0,
    name: '1a',
    label: '1a',
    pressure: 0.17,
    pin: 'rgba(83, 167, 0, 0.4)'
  },
  {
    id: 1,
    name: '1',
    label: '1',
    pressure: 0.23,
    pin: 'rgba(173, 255, 0, 0.4)'
  },
  {
    id: 2,
    name: '2',
    label: '2',
    pressure: 0.3,
    pin: 'rgba(71, 250, 143, 0.4)'
  },
  {
    id: 3,
    name: '3',
    label: '3',
    pressure: 0.38,
    pin: 'rgba(255, 251, 157, 0.4)'
  },
  {
    id: 4,
    name: '4',
    label: '4',
    pressure: 0.48,
    pin: 'rgba(253, 162, 54, 0.4)'
  },
  {
    id: 5,
    name: '5',
    label: '5',
    pressure: 0.6,
    pin: 'rgba(255, 67, 7, 0.4)'
  },
  {
    id: 6,
    name: '6',
    label: '6',
    pressure: 0.73,
    pin: 'rgba(158, 54, 53, 0.4)'
  },
  {
    id: 7,
    name: '7',
    label: '7',
    pressure: 0.85,
    pin: 'rgba(128, 7, 138, 0.4)'
  },
  {
    id: 8,
    name: 'custom',
    label: 'Польз.',
    pressure: ''
  }
]
const zone = 'Ветровые районы принимаются по карте 2, приложения Е СП 20.13330.2016'
const pressure =
  'Нормативные значения ветрового давления принимаются в зависимости от ветрового района по таблице 11.1 СП 20.13330.2016'
const coefficient =
  'Коэффициент надежности по ветровой нагрузке для основной и пиковой ветровых нагрузок следует принимать равным 1,4, согласно СП 20.13330.2016 п. 11'

export default {
  data: () => ({
    isShowOptions: false,
    units: [
      { name: PRESSURE_UNITS.pa.name, label: PRESSURE_UNITS.pa.label, id: uuid.v1() },
      { name: PRESSURE_UNITS.ms.name, label: PRESSURE_UNITS.ms.label, id: uuid.v1() }
    ]
  }),
  components: {
    TerrainTypes,
    Nav,
    Yamap,
    Loading,
    CustomSelect
  },
  watch: {
    currentZone(newValue, oldValue) {
      if (oldValue === 'custom') {
        this.UPDATE_LOCATION_PARAM({ param: 'pressureUnit', val: PRESSURE_UNITS.pa.name })
        this.UPDATE_LOCATION_PARAM({ param: 'converted', val: null })
      }
    }
  },
  methods: {
    ...mapMutations({
      UPDATE_LOCATION_PARAM: 'UPDATE_LOCATION_PARAM',
      CLEAR_ALL_SECTORS_MINOR_RESULT_RECOMMENDED_PARAMS:
        'CLEAR_ALL_SECTORS_MINOR_RESULT_RECOMMENDED_PARAMS',
      UPDATE_USER_INFO_PARAM: 'UPDATE_USER_INFO_PARAM'
    }),
    ...mapActions({
      getContent: 'getContent',
      checkTNTokenAction: 'checkTNToken',
      updateTNToken: 'updateTNToken'
    }),
    updateUserAddress(val) {
      this.UPDATE_USER_INFO_PARAM({
        param: 'objectAddress',
        val
      })
    },
    setCurrentUnit(unit) {
      this.UPDATE_LOCATION_PARAM({
        param: 'pressureUnit',
        val: unit.name
      })
    },
    updateZone(evt) {
      this.UPDATE_LOCATION_PARAM({
        param: 'zone',
        val: evt.name
      })
      this.isShowOptions = false
      this.updatePressure()
    },
    updatePressure() {
      this.UPDATE_LOCATION_PARAM({
        param: 'pressure',
        val: this.findPressure(this.location.zone).pressure
      })
      this.clearAllSectorsMinorResult()
    },
    updateType(val) {
      this.UPDATE_LOCATION_PARAM({
        param: 'type',
        val
      })
      this.clearAllSectorsMinorResult()
    },
    clearAllSectorsMinorResult() {
      if (this.isClearMinorResultsForSectorsNeed) {
        this.CLEAR_ALL_SECTORS_MINOR_RESULT_RECOMMENDED_PARAMS()
      }
    },
    goToWizard() {
      this.$router.push('/wizard/0')
    },
    goToLocation() {
      this.$router.push('/location')
    },
    goToBase() {
      this.$router.push('/wizard/0')
    },
    setZoneParams(data) {
      this.UPDATE_LOCATION_PARAM({
        param: 'zone',
        val: data.zone
      })
      this.isShowOptions = false
      this.UPDATE_LOCATION_PARAM({
        param: 'pressure',
        val: data.pressure
      })
      this.clearAllSectorsMinorResult()
    },
    findPressure(name) {
      return this.districts[this.districts.findIndex(p => p.name === name)]
    },
    checkTNToken() {
      const token = this.$route.query.tnt
      if (token) {
        this.checkTNTokenAction(token)
      } else {
        this.updateTNToken()
      }
    }
  },
  mounted() {
    this.getContent()
    this.$emit('updateStep', stepNumbers.location)
    this.checkTNToken()
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isNextStepBtnDisabled) {
      next()
    }
  },
  computed: {
    ...mapState({
      location: state => state.location,
      materials: state => state.materials,
      sectors: state => state.sectors,
      isLoading: state => state.pages.location.isLoading
    }),
    currentUnit() {
      return this.location.pressureUnit
    },
    computedPressure: {
      get() {
        return this.location.pressure
      },
      set(val) {
        this.UPDATE_LOCATION_PARAM({
          param: 'pressure',
          val: this.currentUnit === PRESSURE_UNITS.pa.name
            ? clamp(val, 0, 10)
            : val
        })
        this.clearAllSectorsMinorResult()
      }
    },
    unitLabel() {
      return this.units.find((item) => item.name === this.currentUnit)?.label
    },
    isCustomZone() {
      return this.location.zone === 'custom'
    },
    currentZone() {
      return this.location.zone
    },
    districts: () => districts,
    zone: () => zone,
    pressure: () => pressure,
    coefficient: () => coefficient,
    filteredDistricts() {
      return this.districts.filter(district => district.name !== this.location.zone)
    },
    zoneLabel() {
      return this.districts.find(district => district.name === this.location.zone)?.label
    },
    isNextStepBtnDisabled() {
      return (
        this.location.zone === null ||
        this.location.pressure === '' ||
        this.location.type === null ||
        this.location.coefficient === ''
      )
    },
    isClearMinorResultsForSectorsNeed() {
      return isSomeSectorMinorResultZoneUnsetted(this.sectors)
    }
  }
}
</script>
<style lang="sass">
.coefficient
  &__pane
    +media((padding: (320: rem(24), 992: rem(40))))
.location
  &__title
    &-wrapper
      width: 100%
    &-types
      +media((padding: (320: rem(40) rem(24) rem(16), 992: rem(51) 0)))
  &__pane
    color: $black
    &-title
      +media((padding-bottom: (320: rem(16), 768: rem(34))))
    &-map
      line-height: 0
      +media((padding-bottom: (320: 0, 768: rem(34))))
      +media((margin: (320: 0 rem(-24), 768: 0)))
      +media((width: (320: calc(100% + 48px), 768: 100%)))
    &-inputs
      display: flex
      align-items: center
      justify-content: center
      flex-wrap: wrap
      column-gap: rem(25)
      &--zone, &--pressure
        display: flex
        flex-direction: row
        align-items: center
        text-align: left
        +media((width: (0: 100%, 768: auto)))
        .title
          font-style: normal
          font-weight: 500
          font-size: rem(14)
          line-height: rem(20)
      &--zone
        +media((justify-content: (320: space-between, 442: flex-start, 768: space-between)))
        +media((padding: (320: rem(12) 0, 768: 0 rem(25) 0 0)))
        $b-l: inset rem(-1) 0 0 $ec
        $b-s: inset 0 rem(-1) 0 $ec
        +media((box-shadow: (320: $b-s, 768: $b-l)))
      &--pressure
        +media((margin: (320: rem(12) 0, 992: 0)))
        .tooltip
          &__btn
            margin-left: rem(14)
            cursor: help
      .input
        background: $white
        border: rem(1) solid $grey
        box-sizing: border-box
        border-radius: rem(4)
        font-family: $main-font
        min-width: rem(80)
        height: rem(34)
        font-style: normal
        font-weight: bold
        font-size: rem(18)
        line-height: rem(22)
        text-align: center
        outline: none
        transition: .5s
        margin-left: rem(17)
        margin-right: rem(10)
        align-items: center
        display: flex
        flex-direction: row
        justify-content: center
        max-width: rem(80)
        &--disabled
          background: $ec
          color: $another-white
          cursor: default
      .tooltip
        &__btn
          line-height: 0
          cursor: help
    .divider
      background: $ec
      pointer-events: none
      +media((width: (320: 100%, 768: rem(1))))
      +media((height: (320: rem(1), 768: rem(34))))
  &__buttons
    &-nav
      justify-content: flex-end
</style>
