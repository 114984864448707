<template>
  <div id="map" class="location__pane-map--map"></div>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    myMap: '',
    searchControl: '',
    zonePoint: '',
    windZones: {}
  }),
  components: {},
  methods: {
    onZonesLoad(json) {
      // Добавляем зоны на карту.
      // eslint-disable-next-line no-undef
      this.windZones = ymaps.geoQuery(json).addToMap(this.myMap)
      // Задаём цвет и контент балунов полигонов.
      this.windZones.each(obj => {
        obj.options.set({
          fillColor: obj.properties.get('fill'),
          fillOpacity: obj.properties.get('fill-opacity'),
          strokeColor: obj.properties.get('stroke'),
          strokeWidth: obj.properties.get('stroke-width'),
          strokeOpacity: obj.properties.get('stroke-opacity')
        })
        obj.properties.set('balloonContent', obj.properties.get('description'))
      })
      // Проверим попадание результата поиска в одну из зон доставки.
      this.searchControl.events.add('resultshow', e => {
        // eslint-disable-next-line no-use-before-define
        this.highlightResult(this.searchControl.getResultsArray()[e.get('index')])
      })
      this.myMap.geoObjects.events.add('balloonopen', e => {
        this.$emit('setZoneParams', {
          zone: e.get('target').properties.get('zone'),
          pressure: e.get('target').properties.get('pressure')
        })
      })
      // Проверим попадание метки геолокации в одну из зон доставки.
      this.myMap.controls.get('geolocationControl').events.add('locationchange', e => {
        // eslint-disable-next-line no-use-before-define
        this.highlightResult(e.get('geoObjects').get(0))
      })
      // При перемещении метки сбрасываем подпись, содержимое балуна и перекрашиваем метку.
      // eslint-disable-next-line no-undef
      zonePoint.events.add('dragstart', () => {
        // eslint-disable-next-line no-undef
        zonePoint.properties.set({ iconCaption: '', balloonContent: '' })
        // eslint-disable-next-line no-undef
        zonePoint.options.set('iconColor', 'black')
      })
      // По окончании перемещения метки вызываем функцию выделения зоны доставки.
      // eslint-disable-next-line no-undef
      zonePoint.events.add('dragend', () => {
        // eslint-disable-next-line no-undef,no-use-before-define
        this.highlightResult(zonePoint)
      })
    },
    init() {
      // eslint-disable-next-line no-undef
      this.myMap = new ymaps.Map(
        'map',
        {
          center: [102.148546, 61.064605],
          zoom: 3,
          controls: ['geolocationControl', 'searchControl']
        },
        {
          minZoom: 2,
          maxZoom: 20
        }
      )
      // eslint-disable-next-line no-undef
      window.zonePoint = new ymaps.GeoObject(
        {
          geometry: { type: 'Point' },
          properties: { iconCaption: 'Адрес' }
        },
        {
          preset: 'islands#blackDotIconWithCaption',
          draggable: true,
          iconCaptionMaxWidth: '315'
        }
      )
      this.searchControl = this.myMap.controls.get('searchControl')
      this.searchControl.options.set({ noPlacemark: true, placeholderContent: 'Поиск' })
      // eslint-disable-next-line no-undef
      this.myMap.geoObjects.add(zonePoint)
      /* fetch(`${this.baseUrl}/data.json`)
        .then(response => response.json())
        .then(json => console.log(json)); */
      axios({
        method: 'get',
        url: '/data.geojson.json',
        responseType: 'json'
      }).then(response => {
        this.onZonesLoad(response.data)
      })
    },
    highlightResult(obj) {
      // Сохраняем координаты переданного объекта.
      const coords = obj.geometry.getCoordinates()
      // Находим полигон, в который входят переданные координаты.
      const polygon = this.windZones.searchContaining(coords).get(0)
      if (polygon) {
        // Уменьшаем прозрачность всех полигонов, кроме того, в который входят
        // переданные координаты.
        this.windZones.setOptions('fillOpacity', 0.4)
        polygon.options.set('fillOpacity', 0.8)
        // Перемещаем метку с подписью в переданные координаты и
        // перекрашиваем её в цвет полигона.
        // eslint-disable-next-line no-undef
        zonePoint.geometry.setCoordinates(coords)
        // eslint-disable-next-line no-undef
        zonePoint.options.set('iconColor', polygon.properties.get('fill'))
        // const zoneFromSearch = polygon.properties.get('zone');
        // const pressureFromSearch = polygon.properties.get('pressure');
        // this.$emit('setZoneParams', { zone: polygon.properties.get('zone'),
        // pressure: polygon.properties.get('pressure') });
        this.$emit('setZoneParams', {
          zone: polygon.properties.get('zone'),
          pressure: polygon.properties.get('pressure')
        })
        this.myMap.balloon.close()
        // Задаем подпись для метки.
        if (typeof obj.getThoroughfare === 'function') {
          // eslint-disable-next-line no-use-before-define
          setData(obj)
          this.$emit('setAddressFromSearchBox', obj.properties.get('text'))
        } else {
          // Если вы не хотите, чтобы при каждом перемещении
          // метки отправлялся запрос к геокодеру,
          // закомментируйте код ниже.
          // eslint-disable-next-line no-undef
          ymaps.geocode(coords, { results: 1 }).then(res => {
            // eslint-disable-next-line no-shadow
            const obj = res.geoObjects.get(0)
            // eslint-disable-next-line no-use-before-define
            setData(obj)
          })
        }
      } else {
        // Если переданные координаты не попадают в полигон, то задаём
        // стандартную прозрачность полигонов.
        this.windZones.setOptions('fillOpacity', 0.4)
        // Перемещаем метку по переданным координатам.
        // eslint-disable-next-line no-undef
        zonePoint.geometry.setCoordinates(coords)
        // Задаём контент балуна и метки.
        // eslint-disable-next-line no-undef
        zonePoint.properties.set({
          iconCaption: 'Данная местность не находится на территории РФ'
        })
        // Перекрашиваем метку в чёрный цвет.
        // eslint-disable-next-line no-undef
        zonePoint.options.set('iconColor', 'black')
      }
      // eslint-disable-next-line no-shadow
      function setData(obj) {
        let address = [obj.getThoroughfare(), obj.getPremiseNumber(), obj.getPremise()].join(' ')

        if (address.trim() === '') {
          address = obj.getAddressLine()
        }
        let price = polygon.properties.get('description')
        // eslint-disable-next-line prefer-destructuring
        price = price.match(/<strong>(.+)<\/strong>/)[1]
        // eslint-disable-next-line no-undef
        zonePoint.properties.set({
          iconCaption: address,
          balloonContent: address,
          balloonContentHeader: price
        })
      }
    }
  },
  watch: {},
  mounted() {
    // eslint-disable-next-line no-undef
    ymaps.ready(this.init)
  },
  computed: {}
}
</script>
<style lang="sass" scoped>
.location
  &__pane
    &-map
      &--map
        width: 100%
        +media((height: (320: rem(280), 992: rem(340))))
</style>
