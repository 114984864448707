<template>
  <div class="terrain-type">
    <input
      type="radio"
      :id="`type--${type.name}`"
      name="terrain-type"
      class="input"
      :value="type.name"
      v-model="computedType"
    />
    <label :for="`type--${type.name}`" class="label">
      <span class="label-img">
        <img :src="type.src" :alt="type.name" />
      </span>
      <span class="label-name">
        <span class="label-name__text">
          Тип <span class="label-name__text--h">местности</span> {{ type.name }}
        </span>
        <span class="btn" :class="labelBtnKit.classes">
          <img src="@/assets/img/mobile-tick.svg" v-if="isCurrentActive" alt="✓" />
          <span>
            {{ labelBtnKit.text }}
          </span>
        </span>
      </span>
      <span class="label-description">
        {{ type.text }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    currentType: {
      type: String,
      default: null
    },
    type: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  name: 'TerrainTypeItem',
  computed: {
    isCurrentActive() {
      return this.currentType === this.type.name
    },
    labelBtnKit() {
      return {
        text: this.isCurrentActive ? 'выбрано' : 'выбрать',
        classes: {
          btn: true,
          'btn--active': this.isCurrentActive
        }
      }
    },
    computedType: {
      get() {
        return this.currentType
      },
      set(type) {
        this.$emit('updateType', type)
      }
    }
  }
}
</script>

<style scoped lang="sass">
$bs: 0 rem(5) rem(20) rgba(51, 51, 51, 0.15), 0 rem(1) rem(4) rgba(51, 51, 51, 0.3)
.terrain-type
  .input
    display: none
    position: absolute
    opacity: 0
    pointer-events: none
    &:checked
      + .label
          box-shadow: $bs
          .label
            &-img
              &:before
                opacity: 1
            &-name
              color: $red
  .label
    height: 100%
    background: rgba($white, .5)
    transition: .5s
    padding: rem(24) rem(26) rem(32)
    cursor: pointer
    display: flex
    +media((flex-wrap: (560: wrap, 768: unset)))
    +media((flex-direction: (320: column, 560: row, 768: column)))
    border-radius: rem(4)
    @media(any-hover: hover)
      &:hover
        background: $white
        box-shadow: $bs
        .label
          &-img
            &:before
              opacity: 1
          &-name
            color: $red
    &-img
      line-height: 0
      position: relative
      +media((order: (0: 2, 992: 1)))
      +media((margin-bottom: (0: rem(24), 560: 0, 768: rem(24), 960: 0)))
      +media((width: (0: 100%, 560: 50%, 768: 100%)))
      &:before
        background-image: url(~@/assets/img/tick.svg)
        content: ""
        position: absolute
        height: 100%
        width: 100%
        background-repeat: no-repeat
        background-position: center
        opacity: 0
        pointer-events: none
        transition: .5s
      img
        width: 100%
        height: auto
    &-name
      width: 100%
      display: flex
      align-items: center
      justify-content: space-between
      +media((margin: (0: 0 0 rem(24), 960: rem(25) 0)))
      +media((order: (0: 1, 960: 2)))

      &__text
        font-style: normal
        font-weight: bold
        font-size: rem(14)
        line-height: rem(18)
        text-transform: uppercase
        transition: .5s
        text-align: left
        width: 100%
        &--h
          +media((display: (320: none, 400: contents, 768: none, 960: contents)))
      .btn
        background: $red
        color: $white
        font-style: normal
        font-weight: 600
        font-size: rem(12)
        line-height: rem(16)
        text-transform: none
        border-radius: rem(4)
        height: 100%
        padding: rem(8) rem(25)
        transition: .5s
        align-items: center
        +media((display: (320: flex, 960: none)))
        img
          margin-right: rem(18)
        &--active
          background: rgba($green, .7)
          padding: rem(8) rem(19) rem(8) rem(14)
    &-description
      +media((width: (0: 100%, 560: 50%, 768: 100%)))
      +media((padding-left: (560: rem(30), 768: 0)))
      order: 3
      font-style: normal
      font-weight: 500
      font-size: rem(12)
      line-height: rem(22)
      color: $light_black
      text-align: left
</style>
