<script>
export default {
  name: 'CustomSelect',
  data: () => ({
    isShowOptions: false,
    chosenValue: null
  }),
  props: {
    options: {
      type: Array,
      required: true
    },
    optionsModel: {
      required: true
    },
    label: {
      default: ''
    }
  },
  methods: {
    updateModel(id) {
      this.$emit('update', this.options.find(option => option.id === id))
    },
    hideOptions() {
      if (this.isShowOptions) this.isShowOptions = false
    }
  },
  computed: {
    selectImg() {
      return this.isShowOptions
        ? require('@/assets/img/location/chevron.svg')
        : require('@/assets/img/location/menu.svg')
    }
  }
}
</script>

<template>
  <div class="select" :class="{ active: isShowOptions }" v-click-outside="hideOptions">
    <div @click="isShowOptions = !isShowOptions" class="select__box">
      <div class="select__box-label">
        {{ label }}
      </div>
      <div class="select__btn">
        <img :src="selectImg" alt="" />
      </div>
    </div>
    <div class="options" v-if="isShowOptions">
      <label
        :for="`option-${item.name}`"
        v-for="item in options"
        :key="item.id"
        :class="`option--${item.name}`"
      >
        <input
          hidden
          type="radio"
          :value="item.name"
          :id="`option-${item.name}`"
          :checked="item.label === label"
          @change="updateModel(item.id)"
        />
        <span class="name">{{ item.label }}</span>
        <span class="pin" :style="{ background: item.pin }" v-if="item.pin"></span>
      </label>
    </div>
  </div>
</template>

<style scoped lang="sass">
.select
  background: $white
  border: rem(1) solid $grey
  box-sizing: border-box
  border-radius: rem(4)
  min-width: rem(80)
  height: rem(34)
  font-style: normal
  font-weight: bold
  +media((font-size: (0: rem(16), 960: rem(18))))
  +media((line-height: (0: rem(20), 960: rem(22))))
  position: relative
  transition: .5s
  cursor: pointer
  margin-left: rem(17)
  margin-right: rem(14)
  &__box
    width: 100%
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    padding: 0 rem(13) 0 rem(16)
    height: rem(34)
    &-label
      margin-right: rem(15)
  &.active
    border-radius: 0 0 rem(4) rem(4)
    border-top-color: transparent
    color: rgba(51, 51, 51, 0.2)
    &:hover
      border-color: $grey
      box-shadow: none
  &:hover
    @extend .common__input-hover
  &__btn
    display: flex
    flex-direction: row
    align-items: center
    path
      transition: .5s
  .options
    color: $black
    display: flex
    flex-direction: column
    background: $white
    z-index: 3
    border: rem(1) solid $grey
    border-bottom: 0
    border-radius: rem(4) rem(4) 0 0
    width: calc(100% + 2px)
    position: absolute
    left: rem(-1)
    box-sizing: border-box
    bottom: 100%
    label
      position: relative
      font-style: normal
      font-weight: bold
      font-size: rem(18)
      line-height: rem(22)
      opacity: 0.9
      text-align: left
      height: rem(34)
      box-shadow: inset 0 rem(-1) 0 $ec
      display: flex
      flex-direction: row
      align-items: center
      justify-content: space-between
      padding: 0 rem(14)
      transition: .5s
      cursor: pointer
      width: 100%
      &.option--custom
        font-size: rem(14)
      &:hover
        color: $red
        .pin
          border-color: $red
      .name
        min-width: rem(36)
      .pin
        height: rem(14)
        width: rem(14)
        border-radius: 0
        border: rem(1) solid $light_black
        box-sizing: border-box
        transition: .5s
</style>
